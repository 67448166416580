// API
// export const apiUrl = "http://localhost:5000/api/"
// export const apiUrl = "https://api-dsr.modernize.shop/api/"
// export const apiUrl = "https://demo-api-rktv.modernize.shop/api/"
// export const apiUrl = "https://demo-api-mart.modernize.shop/api/"
export const apiUrl = "https://api-sdsr.modernize.shop/api/"
// export const apiUrl = "https://api.modernizeg.com/api/"

// Color
export const red = "#FF0000"
export const green = "#01C400"
export const blue = "#001DC4"
export const yellow = "#FFFF00"
export const orange = "#FFA500"
export const modernizeti = "#25B35F"
